$(document).on "ready", ->
  unless in_slideshow?
    in_slideshow = true
    $('.slideshow').each ()->
      window.indebug = new Slide(this)

unless Slide?
  class Slide
    constructor :(el, @current) ->
      @current = 0 unless @current
      @setElements(el)
      @lastIndex = @$slides.length-1
      @$controls.on "click", @do
      @$bullets.on "click", (e) =>
        @goTo(@getIndex($(e.target)))
      @goTo(@current)
      @interval = setInterval @next, 7000
      @intervalScrollTop = setInterval @doScrollTop, 500
      return @

    do: (e) =>
      action =  @[@getAction($(e.target).closest('.control'))]
      if action
        action()
      return @

    doScrollTop: () =>
      clearInterval(@intervalScrollTop)
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      return @

    goTo: (index) =>
      clearInterval(@interval)
      @interval = setInterval @next, 7000
      if index < 0
        @current = @lastIndex
      else if index > @lastIndex || !index
        @current =  0
      else
        @current = index
      @$slides.removeClass('active')
      @$bullets.removeClass('active')
      $(@$slides[@current]).addClass('active')
      $(@$bullets[@current]).addClass('active')
      return @

    last: =>
      @current = @lastIndex
      return @

    previous: =>
      @goTo(@current-1)
      return @

    next: =>
      @goTo(@current+1)
      return @

    first: =>
      @goTo(0)
      return @

    getAction: ($button) ->
      return $button.attr('data-action')

    getIndex: ($bullet) ->

      return $bullet.attr('data-index')

    buildBullets: () ->
      $bullets = $()
      for slide, index in @$slides
        $bullets = $bullets.add($('<li><button class="bullet" data-index="'+index+'"">'+index+'</button></li>'))
      @$el.append($('<ul class="bullets" />').append($bullets))
      return @

    setElements: (@el) ->
      @$el = $(@el)
      @$controls = @find('.control')
      @$screen = @find('.screen')
      @$slider = @find('.slider')
      @$slides = @find('.item')
      @buildBullets()
      @$bullets = @find('.bullet')
      return @

    find: (selector) ->
      return @$el.find(selector)

    disable: ->
      @active = false
      @$controls.hide()
      @goTo(0)

    enable: ->
      @active = true
      @$controls.show()
      @goTo(@current)
